import React from 'react';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { onInviteClientClick } from 'utils/inviteClient';

interface Props {
  buttonType?: 'primary' | 'secondary';
  source: string;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}

export const AddClientButton = ({ buttonType = 'primary', source, style, textStyle }: Props) => {
  return buttonType === 'primary' ? (
    <PrimaryButton
      title="Add Client"
      iconType="plus"
      onClick={() => {
        onInviteClientClick(source);
      }}
      style={style}
      textStyle={textStyle}
    />
  ) : (
    <SecondaryButton
      title="Add Client"
      iconType="plus"
      onClick={() => {
        onInviteClientClick(source);
      }}
      style={style}
      textStyle={textStyle}
    ></SecondaryButton>
  );
};
