import { modal } from 'components/Modals/ModalManager';
import { ProfileImage } from 'components/ProfileImage';
import { setsPluralized } from 'components/WorkoutCell/utils';
import dayjs from 'dayjs';
import {
  ClientWorkoutActivity,
  WeightUnit,
  roundToWholeNumber,
  secondsToWordFormatMinutes,
} from 'hevy-shared';
import router from 'next/router';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { ClipLoader } from 'react-spinners';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';
import styled from 'styled-components';
import { ClickableText } from 'styleguide/Buttons';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { FlexRow } from 'styleguide/Row';
import { TextSMRegular, TextSMMedium, TextXSRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { getRelativeDateWithTime } from 'utils/dateAndTimeUtils';
import { userWeight } from 'utils/units';

const Container = styled(FlexRow)`
  padding-top: ${Spacing.sm}px;
  padding-bottom: ${Spacing.sm}px;
  padding-left: ${Spacing.xs}px;
  gap: ${Spacing.md}px;
  cursor: pointer;
  border-radius: ${CornerRadius.md}px;
  :hover {
    background-color: ${colors.neutral50};
  }
`;

export const WorkoutActivityCell = ({
  activity,
  unit,
  enableClickingClient = false,
}: {
  activity: ClientWorkoutActivity;
  unit: WeightUnit;
  enableClickingClient: boolean;
}) => {
  const [isLoadingWorkout, setIsLoadingWorkout] = useState(false);
  const client = memoryStores.clients.clientForUserId(activity.client_id);
  if (!client) return null;

  const clientFullName = client?.fullName || client?.username;
  const workoutVolume = `${roundToWholeNumber(
    userWeight(activity.total_volume_kg, unit),
  ).toLocaleString()} ${unit}`;

  const prs = activity.prs
    .map(
      pr =>
        localStorageStores.exerciseTemplates.getExercise(pr.exercise_template_id)?.title ??
        client.customExercises.find(e => e.id === pr.exercise_template_id)?.title ??
        'Custom Exercise',
    )
    .filter((value, index, self) => self.indexOf(value) === index);

  const workoutDescription =
    activity.total_volume_kg > 0
      ? ` workout and lifted ${workoutVolume} over ${activity.set_count} ${setsPluralized(
          activity.set_count,
        )}.`
      : ` workout, including ${activity.set_count} ${setsPluralized(activity.set_count)}.`;

  return (
    <Container
      onClick={async () => {
        setIsLoadingWorkout(true);
        try {
          const workout = await memoryStores.workouts.getClientWorkout(activity.workout_id);
          modal.openWorkoutDetailModal(workout.id);
        } catch (error) {
          toast.error('Unable to load workout.');
        }
        setIsLoadingWorkout(false);
      }}
    >
      <View
        onClick={e => {
          if (enableClickingClient) {
            e.stopPropagation();
            e.preventDefault();
            router.push(`/clients/${client.id}`);
          }
        }}
      >
        <ProfileImage source={client.profilePic} diameter={50} />
      </View>

      <View>
        <div>
          <ClickableText
            text={clientFullName}
            style={{ display: 'inline', cursor: 'pointer' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              router.push(`/clients/${client.id}`);
            }}
            enabled={enableClickingClient}
          ></ClickableText>

          <TextSMRegular style={{ display: 'inline', color: colors.neutral900 }}>
            {` has completed a ${secondsToWordFormatMinutes(activity.duration_seconds)} `}
          </TextSMRegular>
          <TextSMMedium style={{ color: colors.primary500, display: 'inline' }}>
            {`${activity.workout_name}`}
          </TextSMMedium>
          <TextSMRegular style={{ display: 'inline', color: colors.neutral900 }}>
            {workoutDescription}
          </TextSMRegular>
          {isLoadingWorkout && (
            <View style={{ paddingLeft: Spacing.xs, display: 'inline' }}>
              <ClipLoader size={10} />
            </View>
          )}
        </div>

        <View>
          {prs.map(pr => (
            <FlexRow style={{ marginTop: Spacing.xs }} key={pr}>
              <CustomizableIcon
                type="pr"
                tint={''}
                style={{ marginRight: Spacing.xs }}
              ></CustomizableIcon>
              <TextXSRegular style={{ color: colors.neutral900, textAlign: 'center' }}>
                {pr}
              </TextXSRegular>
            </FlexRow>
          ))}
        </View>

        <TextXSRegular style={{ marginTop: Spacing.xs }}>
          {getRelativeDateWithTime(dayjs(activity.date))}
        </TextXSRegular>
      </View>
    </Container>
  );
};
