import { modal } from 'components/Modals/ModalManager';
import router from 'next/router';
import { PlanAggregator } from 'state/aggregators/planAggregatorStore';
import { localStorageStores } from 'state/localStorageStores';
import { sendEvent } from './analyticsEvents';

export const onInviteClientClick = (source: string) => {
  sendEvent('inviteClient_click', { source: source });
  if (PlanAggregator.numberOfInvitesLeft <= 0) {
    if (localStorageStores.plan.isPaidPaddlePlan && !localStorageStores.plan.hasActivePlan) {
      // We need to reactivate or fix billing, not upgrade
      router.push('/settings/plan');
      return;
    }
    modal.openUpgradePlanModal({
      source: 'inviteClientClickWithPlanLimits',
      onClose: didUpgrade => {
        if (didUpgrade) {
          modal.openClientInviteModal();
        }
      },
    });
    return;
  }
  modal.openClientInviteModal();
};
