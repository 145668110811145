import { Link } from 'components/Link';
import { ProfileImage } from 'components/ProfileImage';
import dayjs from 'dayjs';
import { ClientBodyweightMeasurementActivity, WeightUnit, roundToTwoDecimal } from 'hevy-shared';
import router from 'next/router';
import React from 'react';
import { memoryStores } from 'state/memoryStores';
import styled from 'styled-components';
import { ClickableText } from 'styleguide/Buttons';
import { SingleCircledIcon } from 'styleguide/CircledIcons';
import { FlexRow } from 'styleguide/Row';
import { TextSMRegular, TextSMMedium, TextXSRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { getRelativeDateWithTime } from 'utils/dateAndTimeUtils';
import { userWeight } from 'utils/units';

const Container = styled(FlexRow)`
  padding-top: ${Spacing.sm}px;
  padding-bottom: ${Spacing.sm}px;
  padding-left: ${Spacing.xs}px;
  gap: ${Spacing.md}px;
  cursor: pointer;
  border-radius: ${CornerRadius.md}px;
  :hover {
    background-color: ${colors.neutral50};
  }
`;

export const BodyweightMeasurementActivityCell = ({
  activity,
  unit,
  showProfilePic,
  enableClickingClient = false,
}: {
  activity: ClientBodyweightMeasurementActivity;
  unit: WeightUnit;
  showProfilePic?: boolean;
  enableClickingClient: boolean;
}) => {
  const client = memoryStores.clients.clientForUserId(activity.client_id);
  if (!client) return null;

  const clientFullName = client.fullName || client.username;
  const clientBodyweight = `${roundToTwoDecimal(userWeight(activity.weight_kg, unit))} ${unit}`;

  const clientTrendValue = `${roundToTwoDecimal(
    userWeight(Math.abs(activity.trend_value), unit),
  )} ${unit} ${activity.trend_value ? (activity.trend_value > 0 ? 'more' : 'less') : ''}`;

  const trendString = activity.trend_value ? `, which is ${clientTrendValue} than last time.` : '';

  return (
    <Link
      href={`/clients/${client.id}/body-measurements?measurementType=weight_kg`}
      target="blank"
      style={{ textDecoration: 'none' }}
    >
      <Container>
        {showProfilePic ? (
          <View
            onClick={e => {
              if (enableClickingClient) {
                e.stopPropagation();
                e.preventDefault();
                router.push(`/clients/${client.id}`);
              }
            }}
          >
            <ProfileImage source={client.profilePic} diameter={50} />
          </View>
        ) : (
          <SingleCircledIcon type="body" diameter={50} />
        )}

        <View>
          <div>
            <ClickableText
              text={clientFullName}
              style={{ display: 'inline', cursor: 'pointer' }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                router.push(`/clients/${client.id}`);
              }}
              enabled={enableClickingClient}
            ></ClickableText>
            <TextSMRegular
              style={{ display: 'inline', color: colors.neutral900 }}
            >{` added a new weight: `}</TextSMRegular>
            <TextSMMedium style={{ color: colors.primary500, display: 'inline' }}>
              {clientBodyweight}
            </TextSMMedium>
            <TextSMRegular style={{ display: 'inline', color: colors.neutral900 }}>
              {trendString}
            </TextSMRegular>
          </div>

          <TextXSRegular style={{ marginTop: Spacing.xs }}>
            {getRelativeDateWithTime(dayjs(activity.date))}
          </TextXSRegular>
        </View>
      </Container>
    </Link>
  );
};
