import React, { useEffect, useRef, useState } from 'react';
import { ClientActivity, WeightUnit } from 'hevy-shared';
import styled from 'styled-components';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextMD } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { CloseButton } from 'components/CloseButton';
import { DividerLine } from 'styleguide/DividerLine';
import { Modal } from 'styleguide/Modal';
import { TertiaryButton } from 'styleguide/Buttons';
import { CardNoPadding } from 'styleguide/Card';
import { FlexRow } from 'styleguide/Row';
import useIsOnScreen from 'utils/useIsOnScreen';
import { EmptyState } from 'components/EmptyState';
import { AddClientButton } from 'components/AddClientButton';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';
import { memoryStores } from 'state/memoryStores';
import { localStorageStores } from 'state/localStorageStores';
import { WorkoutActivityCell } from './Cells/WorkoutActivityCell';
import { ProgressPictureActivityCell } from './Cells/ProgressPictureActivityCell';
import { MeasurementActivityCell } from './Cells/MeasurementActivityCell';
import { InviteAcceptActivityCell } from './Cells/InviteAcceptActivityCell';
import { BodyweightMeasurementActivityCell } from './Cells/BodyweightMeasurementActivityCell';

const ActivityListContainer = styled(View)`
  padding-left: ${Spacing.md}px;
  padding-right: ${Spacing.md}px;
  overflow-y: auto;
`;

const LatestActivityList = ({
  activities,
  unit,
  style,
  isLoadingMore,
  emptyMessage,
  fetchMore,
  showProfilePic,
  showAddClientButton,
  enableClickingClient = false,
}: {
  activities: ClientActivity[];
  unit: WeightUnit;
  style?: React.CSSProperties;
  isLoadingMore: boolean;
  emptyMessage: string;
  showProfilePic?: boolean;
  fetchMore?: () => void;
  showAddClientButton?: boolean;
  enableClickingClient: boolean;
}) => {
  const bottomOfActivitiesRef = useRef<HTMLDivElement>(null);
  const isBottomVisible = useIsOnScreen(bottomOfActivitiesRef);

  useEffect(() => {
    if (isBottomVisible && !!fetchMore) {
      fetchMore();
    }
  }, [isBottomVisible]);

  return (
    <ActivityListContainer style={style}>
      {activities.length ? (
        <View style={{ paddingTop: Spacing.md, paddingBottom: Spacing.md }}>
          {activities.map((activity, index) =>
            activity.type === 'workout' ? (
              <WorkoutActivityCell
                key={`${activity.date}-${activity.workout_id}-${activity.type}-${index}`}
                activity={activity}
                unit={unit}
                enableClickingClient={enableClickingClient}
              />
            ) : activity.type === 'measurement' ? (
              <MeasurementActivityCell
                key={`${activity.date}-${activity.measurement_id}-${activity.type}-${index}`}
                activity={activity}
                showProfilePic={showProfilePic}
                enableClickingClient={enableClickingClient}
              />
            ) : activity.type === 'bodyweight-measurement' ? (
              <BodyweightMeasurementActivityCell
                key={`${activity.date}-${activity.measurement_id}-${activity.type}-${index}`}
                activity={activity}
                unit={unit}
                showProfilePic={showProfilePic}
                enableClickingClient={enableClickingClient}
              />
            ) : activity.type === 'invite-accepted' ? (
              <InviteAcceptActivityCell
                key={`${activity.date}-${activity.client_id}-${activity.type}-${index}`}
                isInvitedByCurrentUser={
                  memoryStores.clients.clientForUserId(activity.client_id)?.coachId ===
                  localStorageStores.account.id
                }
                activity={activity}
              />
            ) : activity.type === 'progress-picture' ? (
              <ProgressPictureActivityCell
                key={`${activity.date}-${activity.client_id}-${activity.type}-${index}`}
                activity={activity}
              />
            ) : null,
          )}
          <div ref={bottomOfActivitiesRef} />
          {isLoadingMore && (
            <View
              style={{ alignItems: 'center', paddingBottom: Spacing.lg, paddingTop: Spacing.md }}
            >
              <MediumHevyActivityIndicator />
            </View>
          )}
        </View>
      ) : (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', gap: Spacing.md }}>
          <EmptyState title="No activities yet" subtitle={emptyMessage} iconType="activity" />
          {showAddClientButton && (
            <AddClientButton
              source="dashboard-empty-activities"
              style={{ maxWidth: 'max-content' }}
              buttonType="secondary"
            />
          )}
        </View>
      )}
    </ActivityListContainer>
  );
};

const ModalContainer = styled(View)`
  width: 684px;
  border-radius: ${CornerRadius.md}px;
`;

export const LatestActivityCard = ({
  latestActivities,
  unit,
  isLoading,
  emptyMessage,
  fetchMore,
  showProfilePic,
  style,
  listHeight,
  showAddClientButton,
  enableClickingClient = false,
}: {
  latestActivities: ClientActivity[];
  unit: WeightUnit;
  isLoading: boolean;
  emptyMessage: string;
  fetchMore?: () => void;
  showProfilePic?: boolean;
  style?: React.CSSProperties;
  listHeight: number;
  showAddClientButton?: boolean;
  enableClickingClient?: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };

  return (
    <CardNoPadding style={{ flex: 1, marginBottom: 0, ...style }}>
      <View>
        <FlexRow style={{ justifyContent: 'space-between', margin: Spacing.md }}>
          <TextMD>Latest Activities</TextMD>

          <FlexRow style={{ justifyContent: 'center' }}>
            <TertiaryButton
              title={latestActivities.length > 0 ? 'See all' : ''}
              onClick={() => {
                if (latestActivities.length > 0) setShowModal(!showModal);
              }}
              disableHovering={true}
              style={{ padding: 0, justifyContent: 'flex-end', height: 'auto', minHeight: 0 }}
            ></TertiaryButton>
          </FlexRow>
        </FlexRow>
        <DividerLine />
        <View style={{ height: listHeight }}>
          {isLoading && latestActivities.length === 0 ? (
            <View style={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <MediumHevyActivityIndicator />
            </View>
          ) : (
            <LatestActivityList
              activities={latestActivities}
              unit={unit}
              isLoadingMore={isLoading}
              emptyMessage={emptyMessage}
              fetchMore={fetchMore}
              showProfilePic={showProfilePic}
              style={{ height: listHeight }}
              showAddClientButton={showAddClientButton}
              enableClickingClient={enableClickingClient}
            ></LatestActivityList>
          )}
          <Modal isOpen={showModal} onClose={onCloseModal}>
            <ModalContainer>
              <FlexRow style={{ justifyContent: 'space-between', padding: Spacing.md }}>
                <TextMD>Latest Activities</TextMD>
                <CloseButton onClick={onCloseModal} />
              </FlexRow>
              <DividerLine />

              {isLoading && latestActivities.length === 0 ? (
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <MediumHevyActivityIndicator />
                </View>
              ) : (
                <LatestActivityList
                  activities={latestActivities}
                  unit={unit}
                  style={{ maxHeight: '100%' }}
                  isLoadingMore={isLoading}
                  emptyMessage={emptyMessage}
                  fetchMore={fetchMore}
                  showProfilePic={showProfilePic}
                  showAddClientButton={showAddClientButton}
                  enableClickingClient={enableClickingClient}
                ></LatestActivityList>
              )}
            </ModalContainer>
          </Modal>
        </View>
      </View>
    </CardNoPadding>
  );
};
