import { ProfileImage } from 'components/ProfileImage';
import dayjs from 'dayjs';
import { ClientInviteAcceptedActivity } from 'hevy-shared';
import router from 'next/router';
import React from 'react';
import { memoryStores } from 'state/memoryStores';
import styled from 'styled-components';
import { FlexRow } from 'styleguide/Row';
import { TextSMMedium, TextSMRegular, TextXSRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { Spacing, CornerRadius } from 'styleguide/spacing';
import { getRelativeDateWithTime } from 'utils/dateAndTimeUtils';

const Container = styled(FlexRow)`
  padding-top: ${Spacing.sm}px;
  padding-bottom: ${Spacing.sm}px;
  padding-left: ${Spacing.xs}px;
  gap: ${Spacing.md}px;
  cursor: pointer;
  border-radius: ${CornerRadius.md}px;
  :hover {
    background-color: ${colors.neutral50};
  }
`;

export const InviteAcceptActivityCell = ({
  activity,
  isInvitedByCurrentUser,
}: {
  activity: ClientInviteAcceptedActivity;
  isInvitedByCurrentUser: boolean;
}) => {
  const client = memoryStores.clients.clientForUserId(activity.client_id);
  if (!client) return null;

  const clientFullName = client.fullName || client.username;

  return (
    <Container onClick={() => router.push(`/clients/${client.id}`)}>
      <ProfileImage source={client.profilePic} diameter={50} />

      <View>
        <div>
          <TextSMMedium
            style={{ display: 'inline', color: colors.primary500 }}
          >{`${clientFullName}`}</TextSMMedium>
          <TextSMRegular style={{ display: 'inline', color: colors.neutral900 }}>{` accepted ${
            isInvitedByCurrentUser ? 'your' : 'an'
          } invite.`}</TextSMRegular>
        </div>

        <TextXSRegular style={{ marginTop: Spacing.xs }}>
          {getRelativeDateWithTime(dayjs(activity.date))}
        </TextXSRegular>
      </View>
    </Container>
  );
};
