export type BodyMeasurementType =
  | 'weight_kg'
  | 'fat_percent'
  | 'neck_cm'
  | 'shoulder_cm'
  | 'chest_cm'
  | 'left_bicep_cm'
  | 'right_bicep_cm'
  | 'left_forearm_cm'
  | 'right_forearm_cm'
  | 'abdomen'
  | 'waist'
  | 'hips'
  | 'left_thigh'
  | 'right_thigh'
  | 'left_calf'
  | 'right_calf';

export const measurementKeyTitleMap: { [key in BodyMeasurementType]: string } = {
  weight_kg: 'Body Weight',
  fat_percent: 'Body Fat',
  neck_cm: 'Neck',
  shoulder_cm: 'Shoulder',
  chest_cm: 'Chest',
  left_bicep_cm: 'Left Bicep',
  right_bicep_cm: 'Right Bicep',
  left_forearm_cm: 'Left Forearm',
  right_forearm_cm: 'Right Forearm',
  abdomen: 'Abdomen',
  waist: 'Waist',
  hips: 'Hips',
  left_thigh: 'Left Thigh',
  right_thigh: 'Right Thigh',
  left_calf: 'Left Calf',
  right_calf: 'Right Calf',
};
