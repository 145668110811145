import { ProfileImage } from 'components/ProfileImage';
import dayjs from 'dayjs';
import { ClientMeasurementActivity } from 'hevy-shared';
import router from 'next/router';
import React from 'react';
import { memoryStores } from 'state/memoryStores';
import styled from 'styled-components';
import { ClickableText, AnchorText } from 'styleguide/Buttons';
import { SingleCircledIcon } from 'styleguide/CircledIcons';
import { FlexRow } from 'styleguide/Row';
import { TextSMRegular, TextXSRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { getRelativeDateWithTime } from 'utils/dateAndTimeUtils';
import { measurementKeyTitleMap, BodyMeasurementType } from 'utils/measurement';

const Container = styled(FlexRow)`
  padding-top: ${Spacing.sm}px;
  padding-bottom: ${Spacing.sm}px;
  padding-left: ${Spacing.xs}px;
  gap: ${Spacing.md}px;
  cursor: pointer;
  border-radius: ${CornerRadius.md}px;
  :hover {
    background-color: ${colors.neutral50};
  }
`;

export const MeasurementActivityCell = ({
  activity,
  showProfilePic,
  enableClickingClient = false,
}: {
  activity: ClientMeasurementActivity;
  showProfilePic?: boolean;
  enableClickingClient: boolean;
}) => {
  const client = memoryStores.clients.clientForUserId(activity.client_id);
  if (!client) return null;

  const clientFullName = client.fullName || client.username;

  return (
    <Container>
      <View
        onClick={e => {
          if (enableClickingClient) {
            e.stopPropagation();
            e.preventDefault();
            router.push(`/clients/${client.id}`);
          }
        }}
      >
        {showProfilePic ? (
          <View
            onClick={e => {
              if (enableClickingClient) {
                e.stopPropagation();
                e.preventDefault();
                router.push(`/clients/${client.id}`);
              }
            }}
          >
            <ProfileImage source={client.profilePic} diameter={50} />
          </View>
        ) : (
          <SingleCircledIcon type="measure" diameter={50}></SingleCircledIcon>
        )}
      </View>

      <View>
        <div>
          <ClickableText
            text={clientFullName}
            style={{ display: 'inline', cursor: 'pointer' }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              router.push(`/clients/${client.id}`);
            }}
            enabled={enableClickingClient}
          ></ClickableText>
          <TextSMRegular style={{ display: 'inline', color: colors.neutral900 }}>
            {` added ${
              activity.measurement_types.length > 1
                ? `${activity.measurement_types.length} new measurements: `
                : 'a new measurement: '
            }`}
          </TextSMRegular>

          {activity.measurement_types
            .sort((a, b) => {
              return a.localeCompare(b);
            })
            .map((m, index, array) => {
              const measurementTitle = measurementKeyTitleMap[m as BodyMeasurementType];
              return (
                <AnchorText
                  key={`${measurementTitle}-${index}`}
                  URL={`/clients/${client.id}/body-measurements?measurementType=${m}`}
                  target="_blank"
                  text={
                    index === array.length - 1 ? `${measurementTitle}` : `${measurementTitle}, `
                  }
                  style={{ display: 'inline' }}
                ></AnchorText>
              );
            })}
          <TextSMRegular style={{ display: 'inline', color: colors.neutral900 }}>.</TextSMRegular>
        </div>

        <TextXSRegular style={{ marginTop: Spacing.xs }}>
          {getRelativeDateWithTime(dayjs(activity.date))}
        </TextXSRegular>
      </View>
    </Container>
  );
};
