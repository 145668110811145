import { modal } from 'components/Modals/ModalManager';
import { ProfileImage } from 'components/ProfileImage';
import dayjs from 'dayjs';
import { ClientAddedProgressPictureActivity } from 'hevy-shared';
import React from 'react';
import { memoryStores } from 'state/memoryStores';
import styled from 'styled-components';
import { FlexRow } from 'styleguide/Row';
import { TextSMMedium, TextSMRegular, TextXSRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { colors } from 'styleguide/colors';
import { Spacing, CornerRadius } from 'styleguide/spacing';
import { getRelativeDateWithTime } from 'utils/dateAndTimeUtils';

const Container = styled(FlexRow)`
  padding-top: ${Spacing.sm}px;
  padding-bottom: ${Spacing.sm}px;
  padding-left: ${Spacing.xs}px;
  gap: ${Spacing.md}px;
  cursor: pointer;
  border-radius: ${CornerRadius.md}px;
  :hover {
    background-color: ${colors.neutral50};
  }
`;

export const ProgressPictureActivityCell = ({
  activity,
}: {
  activity: ClientAddedProgressPictureActivity;
}) => {
  const client = memoryStores.clients.clientForUserId(activity.client_id);
  if (!client) return null;

  const clientFullName = client.fullName || client.username;

  return (
    <Container
      onClick={() => {
        modal.openProgressPictureModal(client.id, activity.measurement_id);
      }}
    >
      <ProfileImage source={client.profilePic} diameter={50} />

      <FlexRow style={{ justifyContent: 'space-between', flex: 1 }}>
        <View>
          <div>
            <TextSMRegular type="secondary" style={{ display: 'inline' }}>
              A new{' '}
            </TextSMRegular>
            <TextSMMedium style={{ color: colors.primary500, display: 'inline' }}>
              Progress Picture
            </TextSMMedium>{' '}
            <TextSMRegular type="secondary" style={{ display: 'inline' }}>
              has been added by {clientFullName}.
            </TextSMRegular>
          </div>

          <TextXSRegular style={{ marginTop: Spacing.xs }}>
            {getRelativeDateWithTime(dayjs(activity.date))}
          </TextXSRegular>
        </View>
        <img
          src={activity.picture_url}
          style={{
            width: 'auto',
            height: 48,
            borderRadius: CornerRadius.xs,
            marginLeft: Spacing.sm,
            marginRight: Spacing.sm,
          }}
        />
      </FlexRow>
    </Container>
  );
};
